import React from "react";
import "./style.scss";
// @ts-ignore
import logo from "../images/logo.png";
import { StaticQuery, Link, graphql } from "gatsby";
import { any } from "async";
import Img from "gatsby-image";

let initialState = {
  menuActive: false,
  selectedItem: "",
  mailingActive: false
};

let defaultProps = {
  data: any,
  callbackFromParent: (msg: string, value: any) => { }
}

type State = Readonly<typeof initialState>
type Props = Readonly<typeof defaultProps>

class Navbar extends React.Component<Props, State> {
  state = initialState;

  constructor(data) {
    super(data);
  }

  selectedItemClass = "border-solid border-b-2 border-white pt-px";
  toggleNav = () => {
    this.setState({ menuActive: !this.state.menuActive });
  };
  
  toggleMailing = () => {
    this.props.callbackFromParent('mailingActive', true);
    //this.setState({mailingActive: true});
  }

  updateSelected = value => {
    if (this.state.selectedItem === value) {
      this.setState({ selectedItem: "" })
    } else {
      this.setState({ selectedItem: value })
    }
  }
  hideMobileMenu() {
    // Above `md`, always visible. For mobile, hidden
    return `${this.state.menuActive ? "visible" : "hidden md:visible"}`
  }
  render() {
    let navStyle = `fixed w-full h-10 z-50 max-w-screen-lg`;
    let navContainer = `
      absolute z-50 w-full max-w-screen-lg 
      bg-red-800 lg:px-2
      flex flex-wrap items-center
    `;
    let leftNav = `h-full p-0 lg:mr-2 inline-flex items-center`;
    let menuStyle = `
      h-10 w-16 my-0 
      md:hidden absolute right-0 top-0 
      flex items-center justify-center
      text-white text-sm font-bold bg-red-700
    `
    let logoStyle = "h-10 my-0 py-2 md:px-2";
    let bottomLinks = `
      flex md:hidden w-full flex-col px-2 bg-white pt-2 border-b border-gray-400
    `;
    let leftLinks = `hidden md:flex justify-end pl-6`;
    let siteLink = `
        items-center inline-flex px-4 pb-1
        text-lg text-red-800 hover:text-red-200 font-condensed no-underline 
        md:text-md md:text-white
        `;
    let appLinkBttn = `
    inline-block text-md w-20 text-center py-1 md:py-2 my-1 px-1 ml-8 md:mx-2 
    leading-none border hover:border-transparent rounded 
    border-red-600 text-red-600 hover:text-black
    md:text-white md:border-white hover:text-red-500 md:hover:bg-white 
    `;
    return (
      <nav className={navStyle}>
        <div className={navContainer}>
          <div className={leftNav} onClick={() => this.updateSelected("Home")}>
            <Link to="/">
              <img className={logoStyle} src={logo} alt="logo" />
            </Link>
            <div className={leftLinks}>
              <Link to="/" className={siteLink} onClick={() => this.updateSelected("Home")} > HOME </Link>
              <Link to="/blog" className={siteLink} onClick={() => this.updateSelected("Blog")} > BLOG </Link>
              <div className={`${siteLink} font-condensed pr-2 text-red-400`}>DOWNLOAD THE APP: </div>
              <a href="https://apps.apple.com/us/app/sum-ergo/id1257476602" >
                <div className={appLinkBttn} >iOS </div>
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.sumergoapp.sumergo">
                <div className={appLinkBttn}> Android </div>
              </a>
            </div>
          </div>
          <div className={menuStyle} onClick={this.toggleNav}>
            {`${this.state.menuActive ? "Close" : "Menu"}`}
          </div>
          <div className={`${bottomLinks} ${this.hideMobileMenu()}`}>
              <Link className={siteLink} to="/" onClick={() => this.updateSelected("Home")} > HOME </Link>
              <Link className={siteLink} to="/blog" onClick={() => this.updateSelected("Blog")} > BLOG </Link>
              <div className={`${siteLink} font-condensed pr-2 text-red-400`}>DOWNLOAD THE APP: </div>
              <a href="https://apps.apple.com/us/app/sum-ergo/id1257476602" >
                <div className={`${appLinkBttn}`}>iOS</div>
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.sumergoapp.sumergo">
                <div className={`${appLinkBttn}`}> Android</div>
              </a>
            </div>
        </div>
      </nav>
    );
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        appleStore: file(relativePath: {eq: "apple-store-badge.png"}) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        androidStore: file(relativePath: {eq: "google-play-badge.png"}) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
`}
    render={(data) => (
      <Navbar data={data} {...props} />
    )} />
)

